<script setup lang="ts">
import { computed, ref } from 'vue';
import Field from './Field.vue';
import { minValue, maxValue } from '@/models/OcularK'
import {useFormulaFormStore} from "@/stores/formulaFormStore";

const formulaFormStore = useFormulaFormStore()

const props = defineProps({
    modelValue: {
        type: Number,
        required: false,
    },
    kNumber: {
      type: Number,
      required: true,
    }
})
const emit = defineEmits(['update:modelValue'])
const valueFromInput = ref('')

const valueToInput = computed({
    get(): string {
        return errorMessage.value 
            ? valueFromInput.value
            : props.modelValue ? props.modelValue.toString() : ''
    },
    set(newValue: string){
        valueFromInput.value = newValue
    }
})

const getInfo = () => {
  if (formulaFormStore?.formulaForm?.type === 'Refrativa Hipermetropia' && props.kNumber === 2) return  '(30.00-52.00)';
  if (formulaFormStore?.formulaForm?.type === 'Ceratotomia Radial' && props.kNumber === 1) return  '(28.00 - 49.00)';

  return '(30.00-49.00)';
}

const errorMessage = ref<string|undefined>()

function emitValue(newValue: string)
{
    const value = parseFloat(newValue.replace(',', '.'))

    let maxValueReal = maxValue;
    if (formulaFormStore?.formulaForm?.type === 'Refrativa Hipermetropia') {
      if (props.kNumber === 2) {
        maxValueReal = 53
      } else if (props.kNumber === 1) {
        maxValueReal = 50
      }
    }
    const minValueReal = formulaFormStore?.formulaForm?.type === 'Ceratotomia Radial' && props.kNumber === 1 ? 28 : minValue;
    if(
        Number.isNaN(value)
        || (value < minValueReal || value > maxValueReal)
    ) {
        errorMessage.value = 'Value invalid'
        valueFromInput.value = newValue
        emit('update:modelValue', 0)
        return
    }

    errorMessage.value = undefined
    emit('update:modelValue', value)
}
</script>

<template>
    <Field 
        alignText="right" 
        :info="getInfo()"
        :error="errorMessage"
        :modelValue="valueToInput"
        @update:modelValue="emitValue"
    />
</template>